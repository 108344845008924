import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MdxLayout.tsx";
import SEO from 'src/components/SEO';
import SelectExample from 'src/components/SelectExample';
import MdxCard from 'src/components/MdxCard';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Prisma Select Convert" description="Prisma Select takes the info: GraphQLResolveInfo object in general graphql arguments (parent, args, context, info) to select object accepted by prisma client. The approach allows a better performance since you will only be using one resolver to retrieve all your request. By doing so, it also eliminates the N + 1 issue" mdxType="SEO" />
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "prisma-select"
      }}>{`Prisma select`}</h2>
      <p>{`Prisma Select takes the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`info: GraphQLResolveInfo`}</code>{` object in general graphql arguments (parent, args, context, info) to select object accepted by `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`prisma client`}</code>{`. The approach allows a better performance since you will only be using one resolver to retrieve all your request. By doing so, it also eliminates the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`N + 1`}</code>{` issue.`}</p>
      <p><strong parentName="p">{`CONTENT`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#install"
          }}>{`Install`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#normal-use"
          }}>{`Normal Use`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#example-query"
          }}>{`Example query`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#api"
          }}>{`Api`}</a>
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "#constructor"
              }}>{`constructor`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "#value"
              }}>{`value`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "#valuewithfilter"
              }}>{`value with filter`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "#valueof"
              }}>{`valueOf`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "#mergedeep"
              }}>{`mergeDeep`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "#filter"
              }}>{`filter`}</a>
              <ul parentName="li">
                <li parentName="ul"><a parentName="li" {...{
                    "href": "#map-models"
                  }}>{`map models`}</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#performance-example"
          }}>{`Performance Example`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/generator/nexus#add-paljs-plugin"
          }}>{`nexus-plugin`}</a></li>
      </ul>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "install"
      }}>{`Install`}</h2>
      <p><a parentName="p" {...{
          "href": "https://npmjs.org/package/@paljs/plugins"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/v/@paljs/plugins.svg",
            "alt": "Version"
          }}></img></a>{`
`}<a parentName="p" {...{
          "href": "https://npmjs.org/package/@paljs/plugins"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/dt/@paljs/plugins.svg",
            "alt": "Downloads/total"
          }}></img></a>{`
`}<a parentName="p" {...{
          "href": "https://paljs.com/"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/l/@paljs/plugins.svg",
            "alt": "License"
          }}></img></a></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}><span parentName="code" {...{
              "className": "token function"
            }}>{`npm`}</span>{` i @paljs/plugins`}</code></pre></div>
      <h2 {...{
        "id": "normal-use"
      }}>{`Normal Use`}</h2>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` PrismaSelect `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/plugins'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// normal resolver`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` resolvers `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  Query`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token function"
            }}>{`user`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` where `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` prisma `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` info`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` select `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`new`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`PrismaSelect`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`info`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`value`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` prisma`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`user`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`findUnique`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
        where`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
        `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span>{`select`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
    </MdxCard>
    <SelectExample mdxType="SelectExample" />
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "api"
      }}>{`API`}</h2>
      <h3 {...{
        "id": "constructor"
      }}>{`constructor`}</h3>
      <p>{`Take two args:`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`info`}</code>{` : `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`GraphQLResolveInfo`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`options`}</code>{`: accept object`}</li>
      </ul>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token comment"
            }}>{`// example`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` defaultFields `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  User`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` id`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` name`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  Type`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` id`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` descriptionRaw`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  Post`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` id`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` body`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// as function you can check if client select some fields to add another to default fields`}</span>{`
  `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`Account`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`select`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` select`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`name `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`firstname`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` lastname`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// the type of options`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`options`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// you can pass object with your models and what the fields you need to include for every model even if user not requested in GraphQL query`}</span>{`
  defaultFields`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`key`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`key`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`boolean`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`select`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`any`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`key`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`boolean`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// array of dmmf object import from generated prisma client default "import {dmmf} from '@prisma/client'"`}</span>{`
  dmmf`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`DMMF`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Document`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h3 {...{
        "id": "methods"
      }}>{`Methods`}</h3>
      <h4 {...{
        "id": "value"
      }}>{`value`}</h4>
      <p>{`Return your converted object.`}</p>
      <p><strong parentName="p">{`Example`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` select `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`new`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`PrismaSelect`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`info`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`value`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h4 {...{
        "id": "valuewithfilter"
      }}>{`valueWithFilter`}</h4>
      <p>{`function take 1 arg:`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`modelName`}</code>{`: Your schema model name to filter returned object and remove any field, not in your schema model`}</li>
      </ul>
      <p><strong parentName="p">{`Example`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` select `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`new`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`PrismaSelect`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`info`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`valueWithFilter`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'User'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h4 {...{
        "id": "valueof"
      }}>{`valueOf`}</h4>
      <p>{`function take 3 args:`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`field`}</code>{`: path to field you want inside type. You can deeb inside nested relation with this `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`user.posts.comments`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`filterBy`}</code>{`: take schema Model name to filter returned object by his schema type`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`mergeObject`}</code>{` : like constructor you can pass here any object to merge with returned data.`}</li>
      </ul>
      <p><strong parentName="p">{`Example of use`}</strong></p>
      <p>{`If We have a mutation called "login", which returns a non schema model type called `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`AuthPayload`}</code>{` that has a schema model type in it,`}</p>
      <p>{`like the following example,`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "graphql"
      }}><pre parentName="div" {...{
          "className": "language-graphql"
        }}><code parentName="pre" {...{
            "className": "language-graphql"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`AuthPayload`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`token`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` String
  `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`user`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` User
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`Mutation`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`login`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token attr-name"
            }}>{`email`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` String`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`!`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`password`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` String`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`!`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` AuthPayload
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <p>{`Here's how the nested type, filter, and to merge custom object would look like.`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` resolver `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  Mutation`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`login`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` email`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` password `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` prisma `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Context`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` info`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` select `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`new`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`PrismaSelect`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`info`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`valueOf`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'user'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'User'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` select`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` id`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
        token`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'token'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
        user`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` prisma`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`user`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`findUnique`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
          where`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` email `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
          `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span>{`select`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
        `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h3 {...{
        "id": "mergedeep"
      }}>{`mergeDeep`}</h3>
      <p>{`This is a static method that you can use to merge our converted object with your custom object.`}</p>
      <p>{`Also, you can use it to merge any object with another object.`}</p>
      <p>{`You can use it if you pass select objects inside the context.`}</p>
      <div {...{
        "className": "gatsby-highlight has-highlighted-lines",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` resolvers `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  Query`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token function"
            }}>{`user`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`_parent`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` where `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` prisma`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` select `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` info`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`      `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`const`}</span>{` mergedObject `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` PrismaSelect`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`mergeDeep`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`select`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` select`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` id`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token boolean"
              }}>{`true`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` prisma`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`user`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`findUnique`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
        where`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
        `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span>{`mergedObject`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h3 {...{
        "id": "filter"
      }}>{`filter`}</h3>
      <p>{`Prisma Select can also be used as a private method to filter your computed fields not included originally in your prisma schema. This feature gives you the ability to customize additional fields in the schema.`}</p>
      <p><strong parentName="p"><em parentName="strong">{`Example`}</em></strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "prisma"
      }}><pre parentName="div" {...{
          "className": "language-prisma"
        }}><code parentName="pre" {...{
            "className": "language-prisma"
          }}><span parentName="code" {...{
              "className": "token comment"
            }}>{`// prisma.schema`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`model`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` User`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id       `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span>{`      `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`autoincrement`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@id`}</span>{`
  firstName     `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`
  lastName     `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "graphql"
      }}><pre parentName="div" {...{
          "className": "language-graphql"
        }}><code parentName="pre" {...{
            "className": "language-graphql"
          }}><span parentName="code" {...{
              "className": "token comment"
            }}>{`# graphql type`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`User`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Int
  `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`firstName`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` String
  `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`lastName`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` String
  `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`fullName`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` String
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <p>{`By adding `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`firstName`}</code>{` and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`lastName`}</code>{` to PrismaSelect in the user field of Query, and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`fullName`}</code>{` in User, the client can request fullName directly.`}</p>
      <div {...{
        "className": "gatsby-highlight has-highlighted-lines",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` PrismaSelect `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/plugins'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` defaultFields `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  User`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` firstName`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` lastName`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` resolvers `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  Query`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token function"
            }}>{`user`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` where `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` prisma `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` info`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`      `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`const`}</span>{` select `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`new`}</span>{` `}<span parentName="span" {...{
                "className": "token class-name"
              }}>{`PrismaSelect`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`info`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` defaultFields `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`value`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` prisma`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`user`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`findUnique`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
        where`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
        `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// this object must not have \`fullName\` because will throw error it's not in our db`}</span>{`
        `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// So we have built in filter to remove any field not in our schema model`}</span>{`
        `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span>{`select`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  User`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`fullName`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`parent`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` args`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` prisma `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Context`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`      `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`return`}</span>{` parent`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`firstName `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`+`}</span>{` parent`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`lastName`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h4 {...{
        "id": "map-models"
      }}>{`Map models`}</h4>
      <p>{`If You need to customize your graphql type and use any name instead of using a prisma model name, you can do this by adding a comment in your schema.prisma file before the model name like this example:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "prisma"
      }}><pre parentName="div" {...{
          "className": "language-prisma"
        }}><code parentName="pre" {...{
            "className": "language-prisma"
          }}><span parentName="code" {...{
              "className": "token comment"
            }}>{`/// @PrismaSelect.map([Account, Profile])`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`model`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` User`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id       `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span>{`      `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`autoincrement`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@id`}</span>{`
  firstName     `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`
  lastName     `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <p>{`Now you can create your GrqphQL types with name you provided and you will get filter by the model `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`User`}</code></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "graphql"
      }}><pre parentName="div" {...{
          "className": "language-graphql"
        }}><code parentName="pre" {...{
            "className": "language-graphql"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`User`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Int
  `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`firstName`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` String
  `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`lastName`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` String
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`Account`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Int
  `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`firstName`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` String
  `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`lastName`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` String
  `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`fullName`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` String
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`Profile`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Int
  `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`firstName`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` String
  `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`lastName`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` String
  `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`anyfield`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` String
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "performance-example"
      }}>{`Performance Example`}</h2>
      <p>{`If we have a `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`Prisma Schema`}</code>{` with the models below.`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "prisma"
      }}><pre parentName="div" {...{
          "className": "language-prisma"
        }}><code parentName="pre" {...{
            "className": "language-prisma"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`model`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` User`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id       `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span>{`       `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`autoincrement`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@id`}</span>{`
  email    `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`    `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@unique`}</span>{`
  password `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`
  posts    `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Post`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`model`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` Post`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id       `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span>{`       `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`autoincrement`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@id`}</span>{`
  published`}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Boolean`}</span>{`   `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token boolean"
            }}>{`false`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  title    `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`
  author   `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` User`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span>{`     `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@relation`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`fields`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`authorId`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` references`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`id`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  authorId `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span>{`
  comments `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Comment`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`model`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` Comment`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id       `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span>{`      `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`autoincrement`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@id`}</span>{`
  contain  `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`
  post     `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Post`}</span>{`     `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@relation`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`fields`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`postId`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` references`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`id`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  postId   `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <p>{`The normal `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`GraphQL Resolvers`}</code>{` to get one User will be like this:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` resolvers `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  Query`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`findUniqueUser`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`_parent`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` args`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` prisma `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` prisma`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`user`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`findUnique`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`args`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  User`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`posts`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`parent`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` args`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` prisma `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` prisma`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`user`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`findUnique`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` where`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` id`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` parent`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`id `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`posts`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`args`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  Post`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`comments`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`parent`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` args`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` prisma `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` prisma`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`post`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`findUnique`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` where`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` id`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` parent`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`id `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`comments`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`args`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <p>{`Let me do GraphQL query to get one user with his posts and comments inside posts and see what is the result:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "graphql"
      }}><pre parentName="div" {...{
          "className": "language-graphql"
        }}><code parentName="pre" {...{
            "className": "language-graphql"
          }}><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  findUniqueUser`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token attr-name"
            }}>{`where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`1`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    id
    posts `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      id
      comments `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
        id
      `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <p>{`Even though we are only requesting ids in the query, the backend is doing 5 queries to select all the table fields as the log shows.`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "text"
      }}><pre parentName="div" {...{
          "className": "language-text"
        }}><code parentName="pre" {...{
            "className": "language-text"
          }}>{`prisma:query SELECT \`dev\`.\`User\`.\`id\`, \`dev\`.\`User\`.\`createdAt\`, \`dev\`.\`User\`.\`email\`, \`dev\`.\`User\`.\`name\`, \`dev\`.\`User\`.\`password\`, \`dev\`.\`User\`.\`groupId\` FROM \`dev\`.\`User\` WHERE \`dev\`.\`User\`.\`id\` = ? LIMIT ? OFFSET ?
prisma:query SELECT \`dev\`.\`User\`.\`id\` FROM \`dev\`.\`User\` WHERE \`dev\`.\`User\`.\`id\` = ? LIMIT ? OFFSET ?
prisma:query SELECT \`dev\`.\`Post\`.\`id\`, \`dev\`.\`Post\`.\`published\`, \`dev\`.\`Post\`.\`title\`, \`dev\`.\`Post\`.\`authorId\`, \`dev\`.\`Post\`.\`createdAt\`, \`dev\`.\`Post\`.\`updatedAt\`, \`dev\`.\`Post\`.\`authorId\` FROM \`dev\`.\`Post\` WHERE \`dev\`.\`Post\`.\`authorId\` IN (?) LIMIT ? OFFSET ?
prisma:query SELECT \`dev\`.\`Post\`.\`id\` FROM \`dev\`.\`Post\` WHERE \`dev\`.\`Post\`.\`id\` IN (?,?,?) LIMIT ? OFFSET ?
prisma:query SELECT \`dev\`.\`Comment\`.\`id\`, \`dev\`.\`Comment\`.\`contain\`, \`dev\`.\`Comment\`.\`postId\`, \`dev\`.\`Comment\`.\`authorId\`, \`dev\`.\`Comment\`.\`createdAt\`, \`dev\`.\`Comment\`.\`updatedAt\`, \`dev\`.\`Comment\`.\`postId\` FROM \`dev\`.\`Comment\` WHERE \`dev\`.\`Comment\`.\`postId\` IN (?,?,?) LIMIT ? OFFSET ?`}</code></pre></div>
      <p>{`With PalJs's Tool `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`GraphQL Resolvers`}</code>{`:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` PrismaSelect `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/plugins'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  Query`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`findUniqueUser`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`_parent`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` args`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` prisma `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` info`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` select `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`new`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`PrismaSelect`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`info`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`value`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` prisma`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`user`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`findUnique`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
        `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span>{`args`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
        `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span>{`select`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <p>{`When we do the same query:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "graphql"
      }}><pre parentName="div" {...{
          "className": "language-graphql"
        }}><code parentName="pre" {...{
            "className": "language-graphql"
          }}><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  findUniqueUser`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token attr-name"
            }}>{`where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`1`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    id
    posts `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      id
      comments `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
        id
      `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <p>{`According to this log, We only get 3 queries using our tool. By using Paljs,
we first query for all the relationship between models, then we select the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`id`}</code>{` from db`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "text"
      }}><pre parentName="div" {...{
          "className": "language-text"
        }}><code parentName="pre" {...{
            "className": "language-text"
          }}>{`prisma:query SELECT \`dev\`.\`User\`.\`id\` FROM \`dev\`.\`User\` WHERE \`dev\`.\`User\`.\`id\` = ? LIMIT ? OFFSET ?
prisma:query SELECT \`dev\`.\`Post\`.\`id\`, \`dev\`.\`Post\`.\`authorId\` FROM \`dev\`.\`Post\` WHERE \`dev\`.\`Post\`.\`authorId\` IN (?) LIMIT ? OFFSET ?
prisma:query SELECT \`dev\`.\`Comment\`.\`id\`, \`dev\`.\`Comment\`.\`postId\` FROM \`dev\`.\`Comment\` WHERE \`dev\`.\`Comment\`.\`postId\` IN (?,?,?) LIMIT ? OFFSET ?`}</code></pre></div>
    </MdxCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      